.content {
    font-family: 'Gilroy' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    margin-bottom: 20px !important;
    color: #000000 !important;
}

.content h3 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: var(--red);
    text-align: left;
}

.content ul {
    list-style: disc;
    padding-left: 15px;
    font-family: 'Gilroy' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #000000 !important;
}

.content li {
    font-family: 'Gilroy' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #000000 !important;
    list-style: disc;
}


.content span:not(.content li span) {
    font-family: 'Gilroy' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    margin-left: 15px;
}

.content p {
    font-family: 'Gilroy' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #000000 !important;
}
