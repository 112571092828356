.error {
    margin-top: 7px;
    margin-left: 23px;
    text-align: start;
    color: #CC1D0E;
    font-weight: 500;
    font-size: 13px;
}

.agreeLink {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-decoration: underline !important;
}

.btn {
    width: 100%;
}

.checkboxContainer {
    padding: 0 15px;
}