.officeCard {
    max-width: 47%;
    margin-bottom: 30px;
}



.offices {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


@media screen and (max-width: 1290px) {
    .officeCard {
        max-width: 100%;
        margin-bottom: 30px;
    }
}