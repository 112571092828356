.checkbox {
    opacity: 0;
}

.label {
    display: block;
    position: relative;
    padding-left: 12px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;

}

.label::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    transform: translateY(10%);
}


.checked::after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 3px;
    width: 10px;
    height: 10px;
    background: #fff;
    transform: translateY(10%);
    border-radius: 2px;

}

/* 
@media screen and  {
    
} */