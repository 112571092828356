.mobileNav {
    position: absolute;
    background: #fff;
    top: 0;
    right: 100%;
    z-index: 10;
    box-shadow: 0px 1px 5px 1px rgba(0,0,0, 0.3);
}

.item {
    padding: 10px;
}

.item:hover {
    background: #fefefe;
}