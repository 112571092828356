.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;
}

.popup {
    position: relative;
    max-width: 707px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #020202;
    padding: 45px 125px;
}

.title {
    margin-bottom: 47px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;
}

.description {

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    color: #FFFFFF;
    margin-bottom: 47px;
}

.cross {
    position: absolute;
    top: 38px;
    right: 38px;
    width: 40px;
    height: 40px;
    background: url('../../../app/img/cross.svg') center no-repeat ;
    cursor: pointer;
}

.hidden {
    opacity: 0;
    z-index: -1;
    transition: 'opacity' 0.2s;
}

.firstButton {
    margin-bottom: 40px;
}

@media screen and (max-width: 775px) {
    .popup {
        max-width: 100%;
        padding: 35px 15px;
    }

    .cross {
        top: 20px;
        right: 15px;
    }
}