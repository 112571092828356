.slideContent {
    padding-bottom: 67px;
    position: relative;
}

.slideContent::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0B488A;
    opacity: 0.4;
}


@media screen and (max-width: 775px) {
    .slideContent {
        padding-bottom: 115px;
    }
}