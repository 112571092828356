.button {
    padding: 22px 35px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
}

.red {
    background: #CC1D0E;
}

.red:hover {
    text-decoration: underline;
}

.red:active {
    background: #f03727 ;
}

.gray {
    border: 1px solid #FFFFFF;
    filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
    background: none; 
}

.gray:hover {
    text-decoration: underline;
}

.gray:active {
    background: #2e2e2e;
}

.disabled,
.disabled:active {
    opacity: 0.5;
    cursor: auto;

}
.disabled:hover {
    text-decoration: none;
}