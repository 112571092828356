.bullet {
    list-style-type: none;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 13px;
    height: 13px;
    margin: 4px;
    text-indent: -999px;
    cursor: pointer;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #fff;
    border-radius: 50%;
    opacity: 1;
}

.active {
    background: #fff;
}

.activeSlide {
    opacity: 1 !important;
}

.bannerSlider {
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important;
    display: flex !important;
    justify-content: space-between !important;
    width: 60% !important;
    background: rgba(11, 72, 138, 0.6) !important;
    border-radius: 20px !important;
    padding: 25px 50px !important;
    align-items: center !important;
}

.wrapper {
    overflow: hidden;
}

.hidden {
    opacity: 0 !important;
}

.pagination {
    flex-direction: column;
}


@media screen and (max-width: 775px) {
    .bannerSlider {
        width: 100% !important;
        border-radius: 0 !important;
        background: #0B488A !important;
        padding: 15px  !important;
    }


    .pagination {
        display: none;
    }
}