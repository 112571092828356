.title {
    max-width: 70%;
    margin-bottom: 117px;
}

.button {
    margin-bottom: 50px;
}


.bannerText {
    padding-top: 50px;
    position: relative;
}

.banner {
    display: block;
    position: relative;
    background-size: cover !important;
    min-height: 460px;
}

.banner::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 65%;
    height: 100%;
    background: #0B488A;
    mix-blend-mode: multiply;
    opacity: 0.6;
    border-radius: 0 20px 20px 0;
}


@media screen and (max-width: 775px) {

    .title {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .button {
        margin-bottom: 10px;
    }

    .banner {
        min-height: 314px;
    }

    .banner::before{
        width: 100%;
        border-radius: 0;
    }

    .bannerText {
        padding: 22px;
    }
    
}