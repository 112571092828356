.servicesRow {
    padding-top: 24px;
}

.icon {
    width: 43px;
    height: 43px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    background-color: #0B488A;
}

.icon svg {
    fill: #fff;
    stroke: #fff;
}

.active .icon svg {
    fill: #0B488A;
    stroke: #0B488A;
}


.card:hover .icon, .card.active .icon {
    background-color: #fff;
}

.card {
    width: 160px;
}

.card:hover .icon svg {
    fill: #0B488A;
    stroke: #0B488A;
}

